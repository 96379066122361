import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import get from 'lodash.get';
import loadable from '@loadable/component';

import 'video-react/dist/video-react.css';
import './index.scss';

import { Video, Section, Container, Row, Column } from 'components/atoms';

const Player = loadable(() => import('video-react/lib/components/Player'));
const BigPlayButton = loadable(() =>
  import('video-react/lib/components/BigPlayButton')
);
const ControlBar = loadable(() =>
  import('video-react/lib/components/control-bar/ControlBar')
);
const PlayToggle = loadable(() =>
  import('video-react/lib/components/control-bar/PlayToggle')
);
const ProgressControl = loadable(() =>
  import('video-react/lib/components/control-bar/ProgressControl')
);

const WidgetWorkVideo = ({ data, className }) => {
  const {
    background_fill,
    background_color,
    size,
    media,
    same_color,
    show_controls,
  } = data;
  const { source_url } = media || {};

  const isFullscreen = size === 'fullscreen';
  const isMedium = size === 'medium';
  const isLarge = size === 'large';

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  const [isReplay, setReplay] = useState(false);
  const [playerState, setPlayerState] = useState(null);
  const [hidePlaceholder, setHidePlaceholder] = useState(null);
  let playerRef = null;

  useEffect(() => {
    function handleStateChange(state) {
      setPlayerState(state);
    }

    if (playerRef !== null) {
      playerRef.subscribeToStateChange(handleStateChange.bind(this));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (playerState !== null) {
      if (playerState.ended === true) {
        setReplay(true);
        playerState.hasStarted = false;
      }
      if (playerState.readyState >= 2) {
        setHidePlaceholder(true);
      }
    }
  }, [playerState]);

  return (
    <Section
      className={cn(
        'widget-work-video',
        { 'widget-work-video_fullscreen': isFullscreen },
        { 'widget-work-offset-bg_fill': background_fill },
        { 'widget-work-offset-size_fullscreen': isFullscreen },
        { 'widget-work-offset-bg_same-color': same_color },
        [className]
      )}
      style={backgroundColorStyle}
    >
      {isFullscreen ? (
        <div
          className={cn(
            'widget-work-video__item',
            'widget-work-video__item_full',
            { 'widget-work-video__item_controls': show_controls }
          )}
        >
          {source_url &&
            (show_controls ? (
              <div
                className={cn('widget-work-video__item-wrapper', {
                  'widget-work-video__item-wrapper_loaded': hidePlaceholder,
                })}
              >
                <Player
                  ref={player => {
                    playerRef = player;
                  }}
                  className={cn(
                    'widget-work-video__item-video',
                    'widget-work-video__item_full-video'
                  )}
                  muted={true}
                >
                  <BigPlayButton
                    position="center"
                    className={cn(
                      isReplay ? 'widget-work-video__item-video__replay' : ''
                    )}
                  />
                  <ControlBar autoHide={false} disableDefaultControls={true}>
                    <PlayToggle />
                    <ProgressControl />
                  </ControlBar>
                  <source src={source_url} />
                </Player>
              </div>
            ) : (
              <Video
                className={cn(
                  'widget-work-video__item-video',
                  'widget-work-video__item_full-video'
                )}
                controls={false}
                autoplay={true}
                loop={true}
                muted={true}
                src={source_url}
              />
            ))}
        </div>
      ) : (
        <Container>
          <Row>
            <Column
              lg={isLarge ? '12' : '10'}
              col={isMedium ? '10' : '12'}
              className={cn({
                'offset-1': isMedium,
                'widget-work-video__col': true,
                'widget-work-video__col_big': isLarge,
              })}
            >
              <div
                className={cn(
                  'widget-work-video__item',
                  {
                    'widget-work-video__item_big': isLarge,
                  },
                  { 'widget-work-video__item_controls': show_controls }
                )}
              >
                {show_controls && (
                  <img
                    className={cn('widget-work-video__item-placeholder', {
                      'widget-work-video__item-placeholder_hidden': hidePlaceholder,
                    })}
                    src={get(media, 'acf.thumbnail.acf.placeholder_base64')}
                    alt=""
                  />
                )}

                {source_url &&
                  (show_controls ? (
                    <div
                      className={cn('widget-work-video__item-wrapper', {
                        'widget-work-video__item-wrapper_loaded': hidePlaceholder,
                      })}
                    >
                      <Player
                        ref={player => {
                          playerRef = player;
                        }}
                        className={cn('widget-work-video__item-video', {
                          'widget-work-video__item_big-video': isLarge,
                        })}
                        muted={true}
                      >
                        <BigPlayButton
                          position="center"
                          className={cn(
                            isReplay
                              ? 'widget-work-video__item-video__replay'
                              : ''
                          )}
                        />
                        <ControlBar
                          autoHide={false}
                          disableDefaultControls={true}
                        >
                          <PlayToggle />
                          <ProgressControl />
                        </ControlBar>
                        <source src={source_url + '#t=0.1'} />
                      </Player>
                    </div>
                  ) : (
                    <Video
                      className={cn('widget-work-video__item-video', {
                        'widget-work-video__item_big-video': isLarge,
                      })}
                      controls={false}
                      autoplay={true}
                      loop={true}
                      muted={true}
                      src={source_url}
                    />
                  ))}
              </div>
            </Column>
          </Row>
        </Container>
      )}
    </Section>
  );
};

WidgetWorkVideo.defaultProps = {
  className: '',
};

WidgetWorkVideo.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default WidgetWorkVideo;
